import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { useTranslation } from "react-i18next";

const PurchaseConfirmModal = ({ open, onClose, onConfirm, title = '', content = '' }) => {

    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: " rounded-lg !bg-[#F7F6FD] min-w-[300px]" }} >
            <div className="flex flex-col gap-6 py-4">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-6 cursor-pointer" onClick={onClose}>
                    <path d="M6.41591 10.997L7.58191 12.163L11.7449 7.99998L7.58091 3.83698L6.41591 5.00598L8.58091 7.17098H0.503906V8.83598H8.53891L6.41591 10.997ZM13.8259 0.505981H2.16891C1.72773 0.5073 1.30499 0.683143 0.993031 0.995106C0.681068 1.30707 0.505225 1.7298 0.503906 2.17098V5.50598H2.16891V2.17098H13.8259V13.828H2.16891V10.498H0.503906V13.829C0.505225 14.2702 0.681068 14.6929 0.993031 15.0049C1.30499 15.3168 1.72773 15.4927 2.16891 15.494H13.8259C14.2671 15.4927 14.6898 15.3168 15.0018 15.0049C15.3137 14.6929 15.4896 14.2702 15.4909 13.829V2.17098C15.4896 1.7298 15.3137 1.30707 15.0018 0.995106C14.6898 0.683143 14.2671 0.5073 13.8259 0.505981Z" fill="#952DD1"/>
                </svg>


                <div className=" text-[#060606] font-bold text-lg text-center px-4">{ title }</div>

                <div className="text-[#716F74] font-medium text-wrap whitespace-pre-wrap max-w-72 text-left px-4 text-xs">
                    { content }
                </div>

                <div className="flex gap-2 w-full">
                    <button className="w-full min-h-14 bg-[#952DD1] font-bold text-white rounded-xl flex items-center justify-center cursor-pointer disabled:bg-opacity-40 outline-none" 
                        onClick={onConfirm}
                    >
                        { t('confirm') }
                    </button>
                    
                    <button
                        className="w-full min-h-14 text-[#952DD1] font-bold rounded-xl flex items-center justify-center cursor-pointer disabled:bg-opacity-40"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default PurchaseConfirmModal;