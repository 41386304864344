import { useState } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import axiosServices from "../utils/axios";

import 'react-image-upload/dist/index.css'

const ContactUsModal = ( { open, onClose, onConfirm } ) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { user, updateProfile } = useAuth();
    const [ phone, setPhone ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ content, setContent ] = useState("");


    const handleSendContactUs = async () => {

        try {
            const res = await axiosServices.postForm(`mailApi`, {
                phone, 
                email, 
                title, 
                content,
                id: user?.id
            });

            if(res?.data?.status === "success") {
                toast.success(t('will_message_to_you'), {
                    autoClose: 3000,
                });
                setTimeout(() => {
                    onClose();
                    navigate("/home");
                }, 2000)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: " rounded-lg bg-[#F7F6FD]" }}>
            <div className="flex flex-col gap-6 py-4">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-6 cursor-pointer" onClick={onClose}>
                    <path d="M6.41591 10.997L7.58191 12.163L11.7449 7.99998L7.58091 3.83698L6.41591 5.00598L8.58091 7.17098H0.503906V8.83598H8.53891L6.41591 10.997ZM13.8259 0.505981H2.16891C1.72773 0.5073 1.30499 0.683143 0.993031 0.995106C0.681068 1.30707 0.505225 1.7298 0.503906 2.17098V5.50598H2.16891V2.17098H13.8259V13.828H2.16891V10.498H0.503906V13.829C0.505225 14.2702 0.681068 14.6929 0.993031 15.0049C1.30499 15.3168 1.72773 15.4927 2.16891 15.494H13.8259C14.2671 15.4927 14.6898 15.3168 15.0018 15.0049C15.3137 14.6929 15.4896 14.2702 15.4909 13.829V2.17098C15.4896 1.7298 15.3137 1.30707 15.0018 0.995106C14.6898 0.683143 14.2671 0.5073 13.8259 0.505981Z" fill="#952DD1"/>
                </svg>
                <div className=" text-[#060606] font-bold text-lg text-center"> Contact Us </div>

                <div className="flex flex-col gap-4">

                    <div className='flex flex-col gap-3 w-full'>
                        <div className='text-[#060606] font-medium text-xs w-full'>{ t('email') }</div>
                        <input
                            className='bg-white w-full h-[55px] rounded-lg px-4 text-[#060606] font-medium outline-none border' dir="ltr"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>


                    <div className='flex flex-col gap-3 w-full'>
                        <div className='text-[#060606] font-medium text-xs w-full'>{ t('phone') }</div>
                        <input
                            className='bg-white w-full h-[55px] rounded-lg px-4 text-[#060606] font-medium outline-none border' dir="ltr"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className='flex flex-col gap-3 w-full'>
                        <div className='text-[#060606] font-medium text-xs w-full'>{ t('title') }</div>
                        <input
                            className='bg-white w-full h-[55px] rounded-lg px-4 text-[#060606] font-medium outline-none border'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    <div className='flex flex-col gap-3 w-full'>
                        <div className='text-[#060606] font-medium text-xs w-full'>{ t('content') }</div>
                        <textarea 
                            className='bg-white w-full h-[55px] rounded-lg px-4 text-[#060606] font-medium outline-none border'
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        >
                        </textarea>
                    </div>

                </div>
                
                <div className="flex flex-flex gap-2 w-full justify-between">
                    <button className="min-h-14 bg-[#952DD1] font-bold text-white rounded-xl flex items-center justify-center cursor-pointer disabled:bg-opacity-40 w-full outline-none" 
                        onClick={() => handleSendContactUs()}
                    >
                        { t('save') }
                    </button>
                    
                    <button
                        className="min-h-14 text-[#952DD1] font-bold rounded-xl flex items-center justify-center cursor-pointer disabled:bg-opacity-40 w-full"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ContactUsModal;