import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';

import AuthGuard from '../../utils/route-guard/AuthGuard';

import Footer from './Footer';
import Header from './Header';


const DashboardLayout = () => {

    const { loading } = useSelector((state) => state.data);

    return (
        <AuthGuard>
            {
                loading && 
                <div className="flex justify-center items-center w-full h-full absolute z-50 bg-gray-900 bg-opacity-30">
                    <BeatLoader color="#952DD1" />
                </div>
            }
            <div className='w-full'>
                <Header />
                <div className='h-[calc(100vh_-_165px)] max-h-[calc(100vh_-_165px)]'>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </AuthGuard>
    )
}


export default DashboardLayout;