import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

const PolicyModal = ({ open, onClose, onConfirm }) => {

    const { t } = useTranslation();
    const { user } = useAuth();
    const divRef = useRef(null);
    const [isBottom, setIsBottom] = useState(false);
    const [isChecked, setIsChecked] = useState(!!user?.policy_accept);
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

    const handleScroll = (e) => {
        const specialDiv = e.target;
        if (specialDiv) {
            const { scrollTop, scrollHeight, clientHeight } = specialDiv;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                setIsBottom(true);
            }
        }
    };

    useEffect(() => {
        const checkScroll = () => {
          const element = divRef.current;
          if (element) {
            const hasVertical = element.scrollHeight > element.clientHeight;
            setHasVerticalScroll(hasVertical);
          }
        };
        
        setTimeout(() => {
            checkScroll(); // Check initially
        }, 1000)
    
        window.addEventListener('resize', checkScroll); // Check on window resize
        return () => window.removeEventListener('resize', checkScroll);
      }, []);

    return (
        <Modal open={open} onClose={() => {}} center showCloseIcon={false} classNames={{ modal: " rounded-lg !bg-[#F7F6FD] min-w-[300px]" }} >
            <div className="flex flex-col gap-6 py-4">

                <div className="text-[#952DD1] font-bold text-2xl text-center">{ t('privacy_policy') }</div>

                <div className="overflow-y-auto max-h-[calc(100vh-17rem)] p-2" onScroll={(e) => handleScroll(e)} ref={divRef}>
                    <div className="text-[#716F74] font-medium text-wrap max-w-72 text-justify" dangerouslySetInnerHTML={{__html: t('policy')}}>
                    </div>
                </div>

                {
                    !user?.policy_accept && 
                    <div className="flex gap-4">
                        <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked((old) => !old)} disabled={!isBottom} />
                        <span>{t('agree_policy')}</span>
                    </div>
                }

                {
                    isChecked && 
                    <div className="flex gap-2 w-full">
                        <button className="w-full min-h-14 bg-[#952DD1] font-bold text-white rounded-xl flex items-center justify-center cursor-pointer disabled:bg-opacity-40" 
                            onClick={onConfirm}
                        >
                            { t('confirm') }
                        </button>
                        
                        <button
                            className="w-full min-h-14 text-[#952DD1] font-bold rounded-xl flex items-center justify-center cursor-pointer disabled:bg-opacity-40 disabled:opacity-40"
                            onClick={onClose}
                            disabled={!user?.policy_accept}
                        >
                            { t('cancel') }
                        </button>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default PolicyModal;