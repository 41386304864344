import { useState, Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Menu, Transition, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import GuestGuard from '../../utils/route-guard/GuestGuard';
import { LANGUAGE, LOCALE } from '../../utils/type';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AuthLayout = () => {

    const [ locale, setLocale ] = useState(localStorage.getItem("locale") || "en");
    const [ langIndex, setLangIndex ] = useState( 0 );
    const [ langChanged, setLangChanged ] = useState( 0 );

    useEffect(() => {
        if(locale === "en") {
            setLangIndex(0)
        } else if (locale === "he") {
            setLangIndex(1)
        } else if (locale === "ar") {
            setLangIndex(2)
        }
    }, [locale]);

    useEffect(() => {
        if(localStorage.getItem("locale") !== LOCALE[langIndex] && langChanged) {
            localStorage.setItem("locale", LOCALE[langIndex]);
            window.location.reload();
        }
    }, [langIndex])

    return (
        <GuestGuard>
            <div className="absolute px-[5%] top-0 right-0 py-4 w-full bg-white flex justify-end">
                <Menu as="div" className="relative inline-block text-left">
                    <div className='flex gap-1 items-center'>
                        {/* <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.24867 0.00687737C7.3175 -0.00250864 7.3873 -0.00228631 7.45607 0.00753791L14.4561 1.00754C14.8256 1.06032 15.1 1.37676 15.1 1.75V8.75C15.1 10.8278 14.2629 13.2247 12.9527 15.1039C11.6551 16.9652 9.75806 18.5 7.55 18.5C6.33913 18.5 5.29176 18.0126 4.33397 17.374C4.32809 17.3701 4.32226 17.3661 4.31649 17.362C1.6934 15.504 0 11.7615 0 8.65V1.75C0 1.38128 0.268019 1.06725 0.632164 1.00931L5.03216 0.309315L5.04866 0.306878L7.24867 0.00687737Z" fill="#FFB54A"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.9802 6.51972C11.2731 6.81261 11.2731 7.28749 10.9802 7.58038L6.58023 11.9804C6.43958 12.121 6.24882 12.2001 6.0499 12.2001C5.85099 12.2001 5.66023 12.121 5.51957 11.9804L4.11957 10.5804C3.82668 10.2875 3.82668 9.81261 4.11957 9.51972C4.41247 9.22683 4.88734 9.22683 5.18023 9.51972L6.0499 10.3894L9.91957 6.51972C10.2125 6.22683 10.6873 6.22683 10.9802 6.51972Z" fill="white"/>
                        </svg> */}
                        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-medium text-[#952DD1] items-center">
                            {
                                LANGUAGE[langIndex]
                            }
                            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.648059 6.22575H7.06206C7.18894 6.22698 7.31267 6.26544 7.41789 6.33635C7.52312 6.40726 7.60521 6.5075 7.65398 6.62464C7.70275 6.74178 7.71606 6.87066 7.69225 6.9953C7.66845 7.11993 7.60857 7.23483 7.52006 7.32575L4.30906 10.5377C4.24922 10.5979 4.17808 10.6456 4.09974 10.6782C4.0214 10.7107 3.9374 10.7275 3.85256 10.7275C3.76772 10.7275 3.68372 10.7107 3.60538 10.6782C3.52704 10.6456 3.4559 10.5979 3.39606 10.5377L0.190059 7.33075C0.0998596 7.24008 0.0385095 7.12474 0.0137367 6.99926C-0.0110361 6.87379 0.00187634 6.74378 0.0508475 6.62563C0.0998187 6.50748 0.182658 6.40646 0.288933 6.33531C0.395207 6.26415 0.520162 6.22603 0.648059 6.22575ZM7.52006 3.39575L4.30906 0.189749C4.24922 0.129611 4.17808 0.0818896 4.09974 0.0493264C4.0214 0.0167632 3.9374 0 3.85256 0C3.76772 0 3.68372 0.0167632 3.60538 0.0493264C3.52704 0.0818896 3.4559 0.129611 3.39606 0.189749L0.190059 3.39575C0.101546 3.48667 0.0416713 3.60157 0.0178646 3.7262C-0.00594214 3.85084 0.00736627 3.97972 0.0561389 4.09686C0.104911 4.214 0.187002 4.31424 0.292226 4.38515C0.397451 4.45606 0.521178 4.49452 0.648059 4.49575H7.06206C7.18894 4.49452 7.31267 4.45606 7.41789 4.38515C7.52312 4.31424 7.60521 4.214 7.65398 4.09686C7.70275 3.97972 7.71606 3.85084 7.69225 3.7262C7.66845 3.60157 7.60857 3.48667 7.52006 3.39575Z" fill="#952DD1"/>
                            </svg>
                        </MenuButton>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <MenuItems className="absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {
                                LANGUAGE?.map((item, index) => (
                                    <MenuItem key={index}>
                                    {({ active }) => (
                                        <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                        onClick={() => {
                                            setLangIndex(index)
                                            setLangChanged(1);
                                        }}
                                        >
                                        {item}
                                        </a>
                                    )}
                                    </MenuItem>
                                ))
                            }
                        </div>
                        </MenuItems>
                    </Transition>
                </Menu>
            </div>

            <Outlet />
        </GuestGuard>
    )
}


export default AuthLayout;