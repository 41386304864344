import { Fragment, useEffect, useState } from 'react';
import { useNavigate, Link, useLocation, useParams, matchPath } from 'react-router-dom';
import { Menu, Transition, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useSelector } from 'react-redux';

import { LANGUAGE, LOCALE, BACKEND_URL } from '../../utils/type';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import PolicyModal from '../../components/PolicyModal';
import ConfirmModal from '../../components/ConfirmModal';
import ProfileModal from '../../components/ProfileModal';
import ContactUsModal from '../../components/ContactUsModal';
import axiosServices from '../../utils/axios';
import PurchaseConfirmModal from '../../components/PurchaseConfirmModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


const Header = () => {
    const navigate = useNavigate();
    const { headerText } = useSelector((state) => state.data);
    const { t } = useTranslation();
    const [locale, setLocale] = useState(localStorage.getItem("locale") || "en");
    const [langIndex, setLangIndex] = useState(0);
    const [langChanged, setLangChanged] = useState(0);
    const [mdoalOpen, setModalOpen] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(0);
    const [profileModalOpen, setProfileModalOpen] = useState(0);
    const [contactUsModalOpen, setContactUsModalOpen] = useState(0);
    const { logout, user, updateProfile, finishQuestion, reduceBalance, questionPurchase } = useAuth();
    const [balance, setBalance] = useState("$0");
    const location = useLocation();
    const { subject_id, course_id, page } = useParams();

    const lecturePath = `/home/${subject_id}/${course_id}/${page}`;
    const lecturesPath = `/home/${subject_id}/${course_id}`;
    const coursesPath = `/home/${subject_id}`;
    const subjectsPath = `/home`;

    useEffect(() => {
        finishQuestion();
    }, [location])

    useEffect(() => {
        if (locale === "en") {
            setLangIndex(0)
        } else if (locale === "he") {
            setLangIndex(1)
        } else if (locale === "ar") {
            setLangIndex(2)
        }
    }, [locale]);

    useEffect(() => {
        if (localStorage.getItem("locale") !== LOCALE[langIndex] && langChanged) {
            localStorage.setItem("locale", LOCALE[langIndex]);
            window.location.reload();
        }
    }, [langChanged, langIndex])

    useEffect(() => {
        setModalOpen(!user?.policy_accept)

        const fetchBalance = async (user_id) => {
            try {
                const { data } = await axiosServices.get(`transactionApi/getBalance?user_id=${user_id}`);
                setBalance(`$${(Number(data?.amount) / 100).toFixed(2)}`);
            } catch (error) {

            }
        }

        if (user?.id) {
            fetchBalance(user?.id);
        }

    }, [user, questionPurchase])

    const handleLogout = () => {
        setConfirmModalOpen(1);
    }

    const handlePolicy = () => {
        setModalOpen(1);
    }

    const handleReadPolicy = async () => {
        if (user?.policy_accept === 1) {
            setModalOpen(0);
            return;
        }
        const res = await axiosServices.patch(`user/${user?.id}`, {
            policy_accept: 1
        });

        if (res?.data?.status === "success") {
            updateProfile(res?.data?.user);
            localStorage.setItem("auth", JSON.stringify(res?.data?.user));
            setModalOpen(0);
        }
    }

    return (
        <div className="h-[85px] fixed top-0 w-full left-0 text-[#C2C2C2] flex flex-col px-[5%] md:[10%] py-2 z-10">
            <div className="flex justify-between gap-3" dir='ltr'>
                <button onClick={() => {
                    if( matchPath(`/home/:subject_id/:course_id/:page`, location.pathname) ) {
                        navigate(lecturesPath)
                    } else if ( matchPath(`/home/:subject_id/:course_id`, location.pathname) ) {
                        navigate(coursesPath)
                    } else if ( matchPath(`/home/:subject_id`, location.pathname) ) {
                        navigate(subjectsPath)
                    } else if ( matchPath(`/home`, location.pathname) ) {
                        
                    } else {
                        navigate(-1)
                    }
                }} className='text-[#060606] font-medium text-sm flex gap-1 items-center' style={{ direction: 'ltr' }}>
                    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.4229 7.02709L7.7399 1.71309C7.92794 1.52452 8.03337 1.26898 8.03299 1.00268C8.03262 0.736377 7.92647 0.481131 7.7379 0.293093C7.54933 0.105054 7.29379 -0.000374075 7.02748 9.97353e-07C6.76118 0.00037607 6.50594 0.106524 6.3179 0.295093L0.292898 6.31509C0.110189 6.49755 0.00513649 6.7436 -0.000268897 7.00176C-0.00567428 7.25991 0.0889861 7.51015 0.263898 7.70009L6.3139 13.7621C6.407 13.8555 6.51759 13.9296 6.63934 13.9802C6.76109 14.0308 6.89162 14.057 7.02348 14.0572C7.15534 14.0574 7.28595 14.0316 7.40784 13.9813C7.52973 13.931 7.64053 13.8572 7.7339 13.7641C7.82727 13.671 7.90138 13.5604 7.95202 13.4386C8.00265 13.3169 8.0288 13.1864 8.02899 13.0545C8.02917 12.9226 8.00339 12.792 7.9531 12.6701C7.90281 12.5483 7.82901 12.4375 7.7359 12.3441L2.4229 7.02709Z" fill="#060606" />
                    </svg>

                    {t('back')}
                </button>
                <div className='flex items-center'>
                    <Menu as="div" className="relative inline-block text-left">
                        <div className='flex gap-1 items-center'>

                            <span className='text-black px-2 font-bold text-lg'>
                                {balance}
                            </span>

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#952DD1" className="size-6 w-full cursor-pointer" onClick={() => setContactUsModalOpen(true)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>

                            <Link to={'/home'} className='w-full h-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#952DD1" className="w-full h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                            </Link>

                            {
                                !user?.avatar &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#952DD1" className="size-6 cursor-pointer" onClick={() => setProfileModalOpen(1)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                            }

                            {
                                user?.avatar &&
                                <img src={`${BACKEND_URL}/storage/${user?.avatar}`} className="w-[30px] h-[30px] rounded-lg cursor-pointer" onClick={() => setProfileModalOpen(1)} alt='' />
                            }

                            <svg className="cursor-pointer w-full" width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handlePolicy()}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.24867 0.00687737C7.3175 -0.00250864 7.3873 -0.00228631 7.45607 0.00753791L14.4561 1.00754C14.8256 1.06032 15.1 1.37676 15.1 1.75V8.75C15.1 10.8278 14.2629 13.2247 12.9527 15.1039C11.6551 16.9652 9.75806 18.5 7.55 18.5C6.33913 18.5 5.29176 18.0126 4.33397 17.374C4.32809 17.3701 4.32226 17.3661 4.31649 17.362C1.6934 15.504 0 11.7615 0 8.65V1.75C0 1.38128 0.268019 1.06725 0.632164 1.00931L5.03216 0.309315L5.04866 0.306878L7.24867 0.00687737Z" fill="#FFB54A" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.9802 6.51972C11.2731 6.81261 11.2731 7.28749 10.9802 7.58038L6.58023 11.9804C6.43958 12.121 6.24882 12.2001 6.0499 12.2001C5.85099 12.2001 5.66023 12.121 5.51957 11.9804L4.11957 10.5804C3.82668 10.2875 3.82668 9.81261 4.11957 9.51972C4.41247 9.22683 4.88734 9.22683 5.18023 9.51972L6.0499 10.3894L9.91957 6.51972C10.2125 6.22683 10.6873 6.22683 10.9802 6.51972Z" fill="white" />
                            </svg>
                            <MenuButton className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-medium text-[#952DD1] items-center">
                                {
                                    LANGUAGE[langIndex]
                                }
                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.648059 6.22575H7.06206C7.18894 6.22698 7.31267 6.26544 7.41789 6.33635C7.52312 6.40726 7.60521 6.5075 7.65398 6.62464C7.70275 6.74178 7.71606 6.87066 7.69225 6.9953C7.66845 7.11993 7.60857 7.23483 7.52006 7.32575L4.30906 10.5377C4.24922 10.5979 4.17808 10.6456 4.09974 10.6782C4.0214 10.7107 3.9374 10.7275 3.85256 10.7275C3.76772 10.7275 3.68372 10.7107 3.60538 10.6782C3.52704 10.6456 3.4559 10.5979 3.39606 10.5377L0.190059 7.33075C0.0998596 7.24008 0.0385095 7.12474 0.0137367 6.99926C-0.0110361 6.87379 0.00187634 6.74378 0.0508475 6.62563C0.0998187 6.50748 0.182658 6.40646 0.288933 6.33531C0.395207 6.26415 0.520162 6.22603 0.648059 6.22575ZM7.52006 3.39575L4.30906 0.189749C4.24922 0.129611 4.17808 0.0818896 4.09974 0.0493264C4.0214 0.0167632 3.9374 0 3.85256 0C3.76772 0 3.68372 0.0167632 3.60538 0.0493264C3.52704 0.0818896 3.4559 0.129611 3.39606 0.189749L0.190059 3.39575C0.101546 3.48667 0.0416713 3.60157 0.0178646 3.7262C-0.00594214 3.85084 0.00736627 3.97972 0.0561389 4.09686C0.104911 4.214 0.187002 4.31424 0.292226 4.38515C0.397451 4.45606 0.521178 4.49452 0.648059 4.49575H7.06206C7.18894 4.49452 7.31267 4.45606 7.41789 4.38515C7.52312 4.31424 7.60521 4.214 7.65398 4.09686C7.70275 3.97972 7.71606 3.85084 7.69225 3.7262C7.66845 3.60157 7.60857 3.48667 7.52006 3.39575Z" fill="#952DD1" />
                                </svg>
                            </MenuButton>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems className="absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    {
                                        LANGUAGE?.map((item, index) => (
                                            <MenuItem key={index}>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                        onClick={() => {
                                                            setLangIndex(index)
                                                            setLangChanged(1);
                                                        }}
                                                    >
                                                        {item}
                                                    </a>
                                                )}
                                            </MenuItem>
                                        ))
                                    }
                                </div>
                            </MenuItems>
                        </Transition>
                    </Menu>

                    <button onClick={() => handleLogout()}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.41591 10.997L7.58191 12.163L11.7449 7.99998L7.58091 3.83698L6.41591 5.00598L8.58091 7.17098H0.503906V8.83598H8.53891L6.41591 10.997ZM13.8259 0.505981H2.16891C1.72773 0.5073 1.30499 0.683143 0.993031 0.995106C0.681068 1.30707 0.505225 1.7298 0.503906 2.17098V5.50598H2.16891V2.17098H13.8259V13.828H2.16891V10.498H0.503906V13.829C0.505225 14.2702 0.681068 14.6929 0.993031 15.0049C1.30499 15.3168 1.72773 15.4927 2.16891 15.494H13.8259C14.2671 15.4927 14.6898 15.3168 15.0018 15.0049C15.3137 14.6929 15.4896 14.2702 15.4909 13.829V2.17098C15.4896 1.7298 15.3137 1.30707 15.0018 0.995106C14.6898 0.683143 14.2671 0.5073 13.8259 0.505981Z" fill="#952DD1" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='flex overflow-hidden'>
                <div className="text-2xl font-bold text-[#060606] leading-8 text-nowrap"> {headerText} </div>
            </div>

            <PolicyModal
                open={mdoalOpen}
                onClose={() => setModalOpen(0)}
                onConfirm={handleReadPolicy}
            />
            <ConfirmModal
                open={confirmModalOpen}
                onClose={() => setConfirmModalOpen(0)}
                onConfirm={() => logout()}
            />
            <ProfileModal
                open={profileModalOpen}
                onClose={() => setProfileModalOpen(0)}
                onConfirm={() => { }}
            />
            <ContactUsModal
                open={contactUsModalOpen}
                onClose={() => setContactUsModalOpen(0)}
                onConfirm={() => { }}
            />

            <PurchaseConfirmModal
                open={questionPurchase}
                onClose={() => reduceBalance()}
                onConfirm={() => {
                    reduceBalance();
                }}
                content={questionPurchase?.content}
                title={questionPurchase?.title}
            />
        </div>
    )
}

export default Header;