import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom';
import './App.css';


import { router } from './routes';
import store from './features/store';

import { JWTProvider as AuthProvider } from './contexts/JWTContext';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from './utils/axios';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en', // Default language
    fallbackLng: 'en',
    resources: {
      en: { translation: {} },
      ar: { translation: {} },
      he: { translation: {} }
    }
});

const getDirection = (language) => {
  const rtlLanguages = ['ar', 'he', 'fa', 'ur'];
  return rtlLanguages.includes(language) ? 'rtl' : 'ltr';
};

function App() {

  const [language, setLanguage] = useState((localStorage.getItem("locale") || 'en'));

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await axios.get(`/translations/${language}`);
        i18n.addResources(language, 'translation', response?.data?.translations);
        i18n.changeLanguage(language);

        document.documentElement.setAttribute('dir', getDirection(language));

      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };
    fetchTranslations();
  }, [language]);

  return (
    <Provider store={store} >
      <AuthProvider>
        <>
          <RouterProvider router={router} />
          <ToastContainer />
        </>
      </AuthProvider>
    </Provider>
  );
}

export default App;
