// export const BACKEND_URL = "http://localhost:8000";
// export const PAYPAL_CLIENT_ID = "AdDvz7SsbnKi7v7Uvm4Ya775eGV9zE-UaUShMH11StEgn9maxqfQ2qD0MaSyhJLCeANcLxQNlmZIxaOw"  // sandbox
// export const ENVIRONMENT = "sandbox"

export const BACKEND_URL = "https://dali-center.com";
export const PAYPAL_CLIENT_ID = "ASMXugOJqzZBuHcEpDoHgt2I5d1VohveHzDc1-e0Cx0DHsGQYgutWc70vA82whp399qkgFkm9iY6vp35"  // live
export const ENVIRONMENT = "production"



export const DIFFICULTY = ["easy", "medium", "hard"];
export const RECOMMENDED = ["recommended", "most_viewed", "recent_updated"];
// export const RECOMMENDED            = ["recommended", "my_selection", "most_popular", "most_viewed", "recent_updated"];
export const MOST_VIEWED = ["most_viewed", "last_seen", "last_added", "movies_i_already_saw"];
export const BALANCE_SORT = ["Date and time", "Only purchased", "Only free", "Only movies", "Only questions", "See all"];
export const LANGUAGE = ["English", "עִברִית", "العربية"];
export const LOCALE = ["en", "he", "ar"];

export const PRICE_LIST = [5, 10, 20, 30, 40, 50];
export const MIN_LIST = [10, 50, 100, 200, 0, 0, 0, 0];
export const QS_LIST = [0, 0, 0, 0, 10, 30, 60, 100];

export const PAYMENT_TYPE = ["BALANCE", "CARD"];